<template>
  <div class="content">
    <van-nav-bar style="background-color: var(--navBg);height: calc(50rem / 16);">
      <template #right>
        <div class="content_right">
          <van-dropdown-menu>
            <van-dropdown-item v-model="dropValue" :options="dropOption" @change="dropChange" />
          </van-dropdown-menu>
        </div>
      </template>
    </van-nav-bar>
    <div class="tab">
      <van-tabs v-model="tabActive" sticky title-inactive-color="#232f3e" title-active-color="#fff" line-width="0"
        line-height="0" @change="tabChange" replace>
        <van-tab v-for="item, index in tabArr" :title="$t('newTabbar.orderPage.tab.' + item.text)">
          <List url="/find/lottery/order" ref="listRef" listWrapClass="order_content" :initParams="{ status: status,day:dayStatus }" :key="activeIndex">
            <template #item="{ data }">
              <div class="order_list">
                <div class="list">
                  <div class="list_label">{{ $t('newTabbar.shopPage.game.order') }}</div>
                  <div class="list_value">{{ data.expect }}</div>
                </div>
                <div class="list">
                  <div class="list_label">{{ $t('newTabbar.shopPage.game.userphone') }}</div>
                  <div class="list_value">{{ formatNumber(data.userphone) }}</div>
                </div>
                <div class="list">
                  <div class="list_label">{{ $t('newTabbar.shopPage.game.amount') }}</div>
                  <div class="list_value">{{ $money(data.total_money) }}</div>
                </div>
                <div class="list">
                  <div class="list_label">{{ $t('newTabbar.shopPage.game.bet') }}</div>
                  <div class="list_right">
                    <div class="list_right_img" v-for="(value, indexs) in data.code_info" :key="indexs">
                      <template v-if="value.name">
                        <span>{{ value.name }}</span>
                        <!-- <van-image width="24" height="24" :src="$img(value.logo && value.logo[0])" /> -->
                      </template>
                    </div>
                  </div>
                </div>
                <!-- <div class="list">
                  <div class="list_label">{{ $t('newTabbar.shopPage.game.result') }}</div>
                  <div class="list_right">
                    <div class="list_right_img" v-for="(value, indexs) in data.open_info" :key="indexs">
                      <template v-if="value.name">
                        <span>{{ value.name }}</span>
                      </template>
                    </div>
                  </div>
                </div> -->
              </div>
            </template>
          </List>
        </van-tab>
      </van-tabs>
    </div>
    <Tabbar active="cart" />
  </div>
</template> 

<script>
import {
  Checkbox,
  CheckboxGroup,
  Card,
  SubmitBar,
  Toast,
  Icon,
  Cell,
  progress,
  Popup,
  NoticeBar,
  CountDown,
  NavBar,
  Tab, Tabs,
  DropdownMenu,
  DropdownItem,
  Image as VanImage
} from "vant";
import List from '@/components/List.vue';
import Tabbar from "@/components/Tabbar";
import { domain } from "@/utils/request";
import { REQUEST_API } from "@/http/api";
import { countTime, getMyTime, deciTurnMoney } from "@/utils/tools";
import { formatDate } from "@/utils/tools";
import { mapGetters } from "vuex";

export default {
  components: {
    [Card.name]: Card,
    [Checkbox.name]: Checkbox,
    [SubmitBar.name]: SubmitBar,
    [CheckboxGroup.name]: CheckboxGroup,
    [Icon.name]: Icon,
    [Cell.name]: Cell,
    [progress.name]: progress,
    [Popup.name]: Popup,
    [NoticeBar.name]: NoticeBar,
    [CountDown.name]: CountDown,
    Tabbar,
    Toast,
    [NavBar.name]: NavBar,
    [Tab.name]: Tab,
    [Tabs.name]: Tabs,
    [DropdownMenu.name]: DropdownMenu,
    [DropdownItem.name]: DropdownItem,
    List,
    [VanImage.name]: VanImage
  },

  data() {
    return {
      domain: domain,
      buyLoading: -1,
      listLoading: false,
      tableList: [
        {
          title: "V1",
        },
        {
          title: `2`,
        },
        {
          title: `3`,
        },
        {
          title: `4`,
        },
      ],
      statusBarList: [
        {
          label: `${this.$t("Cart.state.all")}`,
          key: null,
        },
        {
          label: `${this.$t("Cart.state.going")}`,
          key: "ing",
        },
        // {
        //   label: `${this.$t('Cart.state.paid')}`,
        //   key: 1,
        // },
        // {
        //   label: `${this.$t('Cart.state.frozen')}`,
        //   key: 5,
        // },
        {
          label: `${this.$t("Cart.state.over")}`,
          key: 2,
        },
      ],
      tableKey: 0,
      statusKey: null,
      moneyInfoList: [
        {
          label: `${this.$t("Cart.info.allMoney")}`,
          key: "allMoney",
          value: 0,
        },
        {
          label: `${this.$t("Cart.info.dayAllOrder")}`,
          key: "dayAllOrder",
          value: 0,
        },
        {
          label: `${this.$t("Cart.info.dayTrustMoney")}`,
          key: "dayTrustMoney",
          value: 0,
        },
        {
          label: `${this.$t("Cart.info.yesterdayMoney")}`,
          key: "yesterdayMoney",
          value: 0,
        },
      ],
      list: [],
      loading: false,
      finished: false,
      isBuyBoxShow: false,
      isBuyFailShow: false,
      isBuyFailNotMoneyShow: false,
      percentage: 0,
      level: 1,
      pageNo: 1,
      buyGoodsForm: {}, // 购买当前商品信息，可用可不用
      popupShow: false,
      interval: "",
      pageNow: 1,
      newList: [],
      // userInfo: {},

      dropValue: 0,
      dropOption: [
        { text: `${this.$t('newTabbar.orderPage.rightText')}`, value: 0, isWin: "" },
        { text: `${this.$t('newTabbar.orderPage.today')}`, value: 1, isWin: 1 },
        { text: `${this.$t('newTabbar.orderPage.yesterday')}`, value: 2, isWin: 2 },
        { text: `${this.$t('newTabbar.orderPage.within')}`, value: 3, isWin: 7 },
      ],
      tabArr: [{ text: 'all', isWin: '' }, { text: "iswin", isWin: 1 }, { text: "close", isWin: 2 }, { text: "padding", isWin: 0 }],
      tabActive: 0,
      status: '',
      dayStatus: '',
      activeIndex: 0,
    };
  },

  computed: {
    totalPrice() {
      return this.goods.reduce(
        (total, item) =>
          total + (this.checkedGoods.indexOf(item.id) !== -1 ? item.price : 0),
        0
      );
    },
    ...mapGetters(["userInfo"]),
  },
  created() {
    // this.getTaskOrder();
    this.$store.dispatch('getUserInfo');
    this.$store.dispatch("updateTaskUserInfo");
  },
  mounted() {
    this.initTitlePosition();
  },
  methods: {
    rightClick() {
      console.log('点击右边');
    },
    formatNumber(str) {
      if (typeof str !== 'string') {
        return str;
      }
      const length = str.length;
      if (length <= 6) {
        return str;
      }
      const firstThreeChars = str.slice(0, 3);
      const lastThreeChars = str.slice(length - 3, length);
      const asterisks = '*'.repeat(length - 6);
      return firstThreeChars + asterisks + lastThreeChars;
    },
    tabChange(index) {
      this.activeIndex++
      this.status = this.tabArr[index].isWin;
    },
    dropChange(e){
      this.activeIndex++
      this.dayStatus = this.dropOption[e].isWin;
    },

    toShoppingDetail(item) {
      if (item.url && item.url !== "") {
        window.open(item.url);
      }
    },
    dateEvent(indate) {
      return formatDate("", indate);
    },
    // getUserInfo() {
    //   Toast.loading({
    //     forbidClick: true,
    //     duration: 0,
    //   });
    //   this.$api.getUserInfo(
    //     (res) => {
    //       this.userInfo = res;
    //       Toast.clear();
    //     },
    //     (err) => {
    //       Toast.clear();
    //     }
    //   );
    // },
    /** handle **/
    handleFindExplain() {
      this.popupShow = true;
    },
    handleTable(index) {
      this.listLoading = true;
      if (index === this.tabelKey) {
        return false;
      }
      this.tableKey = index;
      this.pageNow = 0;
      this.getTaskOrder();
    },
    statusSearch(item) {
      this.newList = [];
      this.listLoading = true;
      if (item.key === this.statusKey) {
        return false;
      }
      this.statusKey = item.key;
      this.pageNow = 0;
      this.getTaskOrder();
    },
    handleBuy(item) {
      if (this.userInfo.level) {
        if (this.userInfo.level >= this.tableKey) {
          this.isBuyBoxShow = true;
          this.percentage = 0;

          let auth_timetimer = setInterval(() => {
            this.percentage++;
            if (this.percentage > 30) {
              clearInterval(auth_timetimer);
            }
          }, 20);
          let auth_timetimer2 = setInterval(() => {
            if (this.percentage >= 31 && this.percentage <= 70) {
              this.percentage++;
            }
            if (this.percentage > 60) {
              clearInterval(auth_timetimer2);
            }
          }, 80);

          this.$api.getTask(
            { level: this.tableKey },
            (res) => {
              let auth_timetimer3 = setInterval(() => {
                if (this.percentage >= 61 && this.percentage < 100) {
                  this.percentage++;
                }
                if (this.percentage === 100) {
                  clearInterval(auth_timetimer3);
                  this.interval = setInterval(() => {
                    this.$api.getTask({ level: this.tableKey }, (result) => {
                      if (result.status == 1) {
                        this.pageNow = 0;
                        this.getTaskOrder();
                        clearInterval(this.interval);
                      }
                    });
                  }, 5000);
                  setTimeout(() => {
                    this.isBuyBoxShow = false;
                    this.$toast(
                      "Order has been obtained, please wait for the order!"
                    );
                  }, 300);
                }
              }, 30);
            },
            (err) => {
              let auth_timetimer3 = setInterval(() => {
                if (this.percentage >= 61 && this.percentage < 99) {
                  this.percentage++;
                }
                if (this.percentage === 99) {
                  clearInterval(auth_timetimer3);
                  setTimeout(() => {
                    this.isBuyBoxShow = false;
                    if (typeof err == "string") {
                      this.$toast(err + "!");
                    } else {
                      this.$toast("Network timeout, please try again later!");
                    }
                  }, 20000);
                }
              }, 100);
            }
          );
        } else {
          this.$toast("Grade mismatch!");
        }
      } else {
        // this.$api.getUserInfo();
        this.$store.dispatch("getUserInfo");
        this.handleBuy();
      }
    },
    handleBuyPercentage(item) {
      if (this.percentage < 100) {
        return false;
      }
      this.sendBuy(this.buyGoodsForm.id);
    },
    handleInviteFriends() {
      this.$router.push("InviteFriends"); // 邀请好友
    },
    handleBuyVip() {
      this.isBuyFailShow = false;
      this.$router.push("Recharge");
    },
    handlecolsePopBox() {
      this.isBuyBoxShow = false;
      this.isBuyFailShow = false;
      this.isBuyFailNotMoneyShow = false;
      this.buyGoodsForm = {};
    },
    handleColesZZ() {
      this.isBuyBoxShow = false;
      this.isBuyFailShow = false;
      this.isBuyFailNotMoneyShow = false;
      this.buyGoodsForm = {};
    },
    /** api **/
    onLoad() {
      // 异步更新数据
      // setTimeout 仅做示例，真实场景中一般为 ajax 请求
      setTimeout(() => {
        for (let i = 0; i < 10; i++) {
          this.list.push(this.list.length + 1);
        }
        // 加载状态结束
        this.loading = false;
        this.finished = true;
        // 数据全部加载完成
        if (this.list.length >= 40) {
          this.finished = true;
        }
      }, 1000);
    },
    getGoodsList() {
      if (this.finished) {
        return false;
      }
      let form = {
        uid: this.userInfo[1].data.player.uid,
        level: this.level,
        cmd: "201002",
      };
      REQUEST_API(form)
        .then((r) => {
          if (!r.data.sc) {
            if (r.data.notifyList[0].data.length) {
              this.list.push(...r.data.notifyList[0].data);
              this.finished = true;
              this.pageNo++;
              if (this.finished) {
                this.loading = false;
              }
            } else {
              this.list = [];
              this.loading = false;
              this.finished = true;
            }
          } else {
            Toast(this.$t("Hint.failed"));
            this.list = [];
            this.loading = false;
            this.finished = true;
          }
        })
        .catch((e) => {
          console.info(e);
        });
    },
    getMoneyInfo() {
      let form = {
        uid: this.userInfo[1].data.player.uid,
        cmd: "201004",
      };
      REQUEST_API(form)
        .then((r) => {
          if (!r.data.sc) {
            let moneyForm = r.data.notifyList[0].data;
            this.moneyInfoList[0].value = deciTurnMoney(moneyForm.diamond) || 0;
            this.moneyInfoList[1].value = moneyForm.size || 0;
            this.moneyInfoList[2].value = deciTurnMoney(moneyForm.price) || 0;
            this.moneyInfoList[3].value =
              deciTurnMoney(moneyForm.last_reward) || 0;
          } else {
            Toast(this.$t("Hint.failed"));
          }
        })
        .catch((e) => {
          console.info(e);
        });
    },
    sendBuy(id) {
      let form = {
        id,
        cmd: "201003",
        uid: this.userInfo[1].data.player.uid,
      };
      REQUEST_API(form)
        .then((r) => {
          if (!r.data.sc) {
            Toast(this.$t("Hint.success"));
            this.handleColesZZ();
            this.list = [];
            this.finished = false;
            this.pageNo = 1;
            this.getMoneyInfo();
          } else if (r.data.sc === 1002) {
            this.isBuyBoxShow = false;
            // this.isBuyFailShow = true
            this.isBuyFailNotMoneyShow = true;
            Toast(this.$t("Hint.failed"));
          } else if (r.data.sc === 1211) {
            Toast(this.$t("Hint.goodsNumNo"));
          } else {
            Toast(this.$t("Hint.failed"));
          }
        })
        .catch((e) => {
          console.info(e);
        });
    },
    /** 其他 **/
    init() {
      // this.userInfo = JSON.parse(localStorage.getItem("user-info"));
      this.level = this.$route.query.id ? Number(this.$route.query.id) + 1 : 1;
      this.tableList.forEach((_, i) => {
        if (i + 1 === this.level) {
          this.tabelKey = _.key;
        }
      });
    },
    initTitlePosition() {
      if (this.tableKey > 2) {
        let title_wrap = document.querySelector(".classify");
        title_wrap.scrollLeft = document.documentElement.clientWidth;
      }
    },
    getLevels() {
      this.$api.getLevel((res) => {
        this.tableList = res;
      });
    },
    closeBuy() {
      this.isBuyBoxShow = false;
    },
    getTaskOrder(success, fail) {
      this.listLoading = true;
      this.loading = true;
      // if (this.finished) {
      //   return false;
      // }
      // this.finished = false;
      if (this.newList.length) {
        this.pageNow++;
      }
      let data = {
        // level: this.tableKey,
        page: this.pageNow,
        // status: this.statusKey
      };
      this.$api.getTaskOrder(
        data,
        (res) => {
          if (this.pageNow == 1) {
            this.newList = res.lists;
          } else {
            // for (let i = 0; i < res.lists.length; i++) {
            //   const item = res.lists[i];
            //   this.newList.push(item);
            // }
            // this.newList.concat(res.lists);
            this.newList = [...this.newList, ...res.lists];
          }
          this.listLoading = false;
          this.loading = false;
          if (!res.hasMorePages) {
            this.finished = true;
          }
          // this.finished = true;
          if (success) {
            success(res);
          }
        },
        (err) => {
          // this.newList = [];
          this.listLoading = false;
          this.loading = false;
          this.finished = true;
          if (fail) {
            fail(err);
          }
        }
      );
    },
    toPay(row, index) {
      // this.newList[index].buyLoading = true;
      if (this.buyLoading !== index) {
        this.buyLoading = index;
        this.$api.payTaskOrder(
          row.id,
          (res) => {
            const { data } = res;
            const newList = [...this.newList];
            newList[index] = data;
            // this.buyLoading = -1;
            this.newList = newList;
            this.pageNow = 0;
            this.$store.dispatch("getUserInfo");
            this.getTaskOrder(
              (res) => {
                this.buyLoading = -1;
              },
              (err) => {
                this.$toast(err);
              }
            );
          },
          (err) => {
            if (err.ret == 0) {
              this.$toast(err.msg);
              setTimeout(() => {
                this.$router.push({ name: "NewRecharge" });
              }, 1000);
            } else {
              if (err.msg) {
                this.$toast(err.msg);
              }
            }
          }
        );
      }
    },
  },
  beforeDestroy() {
    this.activeIndex=0
  },
};
</script>

<style lang="less" scoped>
@themeColor: var(--navBg);
@white: #fff;

.widthCalc(@name, @width) {
  @{name}: calc(~"@{width}" / 16)
}

.flex(@jus: normal, @ali: normal) {
  display: flex;
  justify-content: @jus;
  align-items: @ali;
}

.backgroundColor(@colors) {
  background-color: @colors;
}

.glossy {
  background-image: linear-gradient(to bottom, rgba(255, 255, 255, .3), rgba(255, 255, 255, 0) 50%, rgba(0, 0, 0, .12) 51%, rgba(0, 0, 0, .04)) !important;
}

.ellipsis {
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
}

.content {
  height: calc(100vh - (50rem / 16));
  background: #f4f8fb;
  overflow: hidden;

  /deep/ .van-nav-bar__content {
    height: 100%;
  }

  .content_right {
    /deep/ .van-dropdown-menu__bar {
      background-color: transparent;

      .van-ellipsis {
        font-size: calc(14rem / 16);
        color: #fff;
        font-weight: 600;
        margin-right: calc(8rem / 16);
      }

      .van-dropdown-menu__title::after {
        border: 5px solid;
        border-color: transparent transparent #fff #fff;
        margin-top: -8px;
      }

      .van-dropdown-menu__title--down::after {
        transform: rotate(-45deg);
      }
    }
  }

  .tab {
    margin-top: calc(12rem / 16);

    /deep/ .van-tabs__wrap {
      height: calc(36rem / 16);

      .van-tab {
        border-right: 1px solid #232f3e;
      }

      .van-tab:nth-child(4) {
        border-right: none;
      }
    }

    /deep/ .van-tab--active {
      background-color: #232f3e;
    }
  }

  // > .title {
  //   height: calc(49rem / 16);
  //   display: flex;
  //   align-items: center;
  //   justify-content: center;
  //   background: #fff;
  //   color: #464646;
  //   font-weight: 400;
  //   font-size: calc(18rem / 16);
  //   position: relative;
  //   width: 100%;
  //   margin-bottom: calc(12rem / 16);
  // }




  // > .money_info_wrap {
  //   width: 100%;
  //   background: #fff;
  //   position: relative;
  //   padding: calc(14rem / 16) 0;
  //   min-height: calc(26rem / 16);
  //   margin-bottom: calc(12rem / 16);

  //   > .line_center_wrap {
  //     width: 100%;
  //     height: 100%;
  //     position: absolute;
  //     top: 0;
  //     left: 0;
  //     display: flex;
  //     justify-content: center;
  //     align-items: center;

  //     > .line_center {
  //       width: 1px;
  //       height: 80%;
  //       background-color: rgb(226, 226, 226);
  //     }
  //   }

  //   > .money_content {
  //     width: 100%;
  //     height: 100%;
  //     display: flex;
  //     // justify-content: space-between;
  //     justify-content: center;
  //     align-items: center;

  //     > .money_item {
  //       width: 50%;
  //       height: 100%;
  //       display: flex;
  //       align-items: center;
  //       justify-content: center;

  //       > .item_content {
  //         padding: 0 calc(6rem / 16);
  //         text-align: center;
  //         line-height: 1.5;
  //         font-weight: bold;

  //         > .title {
  //           font-size: calc(12rem / 16);
  //         }

  //         > .data {
  //           font-size: calc(16rem / 16);
  //           color: #eaad5f;
  //         }
  //       }
  //     }
  //   }
  // }

  // > .hint {
  //   width: 100%;
  //   padding: calc(4rem / 16) 0;
  //   background-color: rgba(234, 173, 95, 0.3);
  //   color: #8d6128;
  //   text-align: center;
  //   font-size: calc(12rem / 16);
  // }

  .placeholder {
    width: 100%;
    height: calc(12rem / 16);
    background-color: rgb(248, 248, 248);
  }

  .wrap {
    height: calc(100vh - (100rem / 16) - (80rem / 16));
    background-color: rgb(248, 248, 248);
    overflow: hidden;
    overflow-y: auto;
    padding: calc(16rem / 16);
    padding-bottom: 1000px;

    .nomore {
      width: 100%;

      >img {
        width: 100%;
      }
    }

    .goods {
      width: 100%;
      // height: calc(145rem / 16);
      background: #fff;
      margin-bottom: calc(8rem / 16);

      >.good_content {
        height: 100%;
        padding: 0 calc(16rem / 16);

        >.top {
          width: 100%;
          display: flex;
          justify-content: space-between;
          font-size: calc(14rem / 16);
          font-weight: bold;
          padding: calc(8rem / 16) 0;

          >.shop_name {
            color: #eaad5f;
          }

          >.tobuy {
            color: #fff;
            padding: calc(6rem / 16) calc(6rem / 16);
            background-color: #dd3043;
            border-radius: 4px;
          }
        }

        >.bottom {
          width: 100%;
          display: flex;
          justify-content: space-between;
          padding: calc(8rem / 16) 0;
          line-height: 1;

          >.left {
            max-width: 40%;

            >img {
              width: 22vw;
              height: 24vw;
              border-radius: 2px;
            }
          }

          >.right {
            display: flex;
            flex-direction: column;
            align-items: flex-end;
            max-width: 60%;

            >.return {
              font-size: calc(14rem / 16);
              margin-bottom: calc(12rem / 16);
            }

            >.desc {
              color: #666;
              font-size: calc(12rem / 16);
              margin-bottom: calc(38rem / 16);
              max-width: 100%;
              word-break: break-all;
            }

            >.time {
              color: #999;
              font-size: calc(12rem / 16);
            }
          }
        }
      }

      >.vantActive {
        width: 100%;
      }
    }
  }

  >.statusBar {
    // width: 98%;
    width: 100%;
    display: flex;
    margin: 0 auto;
    border-top-left-radius: 4px;
    border-top-right-radius: 4px;
    overflow: hidden;

    >div {
      width: 33.33%;
      height: calc(38rem / 16);
    }
  }

  >.buy-box {
    width: 100%;
    height: 100%;
    // position: relative;
    position: fixed;
    // top: -100%;
    top: 0;

    >.cont {
      width: calc(350rem / 16);
      height: calc(460rem / 16);
      // background: url('../../assets/Img/goods/img/DDSC_0001_xdz.png') no-repeat;
      position: absolute;
      z-index: 44;
      left: 50%;
      top: 50%;
      transform: translate(-50%, -50%);

      >div {
        width: calc(350rem / 16);
        height: calc(460rem / 16);
        background: url("../../assets/Img/goods/img/DDSC_0001_xdz.png") no-repeat;
        background-size: 100% 100%;

        >.goodsInfo {
          padding-top: calc(250rem / 16);
          display: flex;
          align-items: center;
          // justify-content: space-between;
          margin-bottom: calc(5rem / 16);
          position: relative;

          >.close {
            position: absolute;
            right: calc(10rem / 16);
            top: calc(120rem / 16);
          }

          >div {
            display: flex;
            align-items: center;
            font-size: calc(13rem / 16);
            color: #9b8383;

            >img {
              width: calc(30rem / 16);
              height: calc(30rem / 16);
              border-radius: 6px;
            }

            >div {
              display: flex;
              flex-direction: column;
              margin-left: calc(15rem / 16);
              width: calc(70rem / 16);

              >span {}
            }
          }

          >p {
            font-size: calc(18rem / 16);
            text-align: center;
            color: #9b8383;
            // margin-bottom: calc(2rem / 16);
            margin: 0 0 calc(2rem / 16) calc(15rem / 16);
          }
        }

        >div {
          width: 98%;
          margin-left: calc(6rem / 16);
          // margin: auto;
        }

        >span {
          font-size: calc(18rem / 16);
          display: block;
          text-align: center;
          color: #9b8383;
          // margin: calc(15rem / 16) calc(22rem / 16) calc(2rem / 16) 0;
          margin: calc(15rem / 16) 0 calc(2rem / 16) 0;
        }

        >button {
          width: calc(100% - (60rem / 16));
          height: calc(60rem / 16);
          margin: auto;
          display: block;
          // margin-right: calc(40rem / 16);
          border-radius: 30px;
          border: 0;
          color: #fff;
          background: #c4cec9;
          font-size: calc(22rem / 16);
          font-weight: 200;

          &.on {
            background: #48db8d;
          }
        }
      }
    }
  }

  >.buy-fail {
    width: 100%;
    height: 100%;
    position: relative;
    top: -100%;

    >.cont {
      width: calc(350rem / 16);
      height: calc(350rem / 16);
      position: absolute;
      z-index: 44;
      left: 50%;
      top: 50%;
      transform: translate(-50%, -50%);

      >div {
        width: calc(350rem / 16);
        height: calc(350rem / 16);
        background: url("../../assets/Img/goods/img/DDSC_0000_sbdb.png") no-repeat;
        background-size: 100% 100%;
        color: #9b8383;

        >p {
          text-align: center;
          font-size: calc(17rem / 16);
        }

        >p:nth-child(1) {
          font-size: calc(30rem / 16);
          line-height: calc(75rem / 16);
          margin-bottom: calc(2rem / 16);
        }

        >p:nth-child(2) {}

        >p:nth-child(3) {
          font-size: calc(14rem / 16);
          margin: calc(20rem / 16) 0 calc(4rem / 16);
        }

        >p:nth-child(4) {
          color: #e6a30c;
          margin-bottom: calc(15rem / 16);
          font-size: calc(14rem / 16);
        }

        >.btn-box {
          display: flex;
          align-items: center;
          justify-content: space-between;
          justify-content: center;
          width: 90%;
          margin: calc(28rem / 16) auto 0;

          >button {
            width: calc(130rem / 16);
            width: calc(260rem / 16);
            height: calc(55rem / 16);
            border-radius: 35px;
            border: 0;
            background: #48db8d;
            color: #fff;
            font-size: calc(20rem / 16);
            font-weight: 200;
          }
        }
      }
    }
  }

  >.buy-fail-not-money {
    width: 100%;
    height: 100%;
    position: relative;
    top: -100%;

    >.cont {
      width: calc(350rem / 16);
      height: calc(350rem / 16);
      position: absolute;
      z-index: 44;
      left: 50%;
      top: 50%;
      transform: translate(-50%, -50%);

      >div {
        width: calc(350rem / 16);
        height: calc(350rem / 16);
        background: url("../../assets/Img/goods/img/DDSC_0000_sbdb.png") no-repeat;
        background-size: 100% 100%;
        color: #9b8383;

        >p {
          text-align: center;
          font-size: calc(20rem / 16);
        }

        >p:nth-child(1) {
          font-size: calc(30rem / 16);
          line-height: calc(80rem / 16);
          margin-bottom: calc(2rem / 16);
        }

        >p:nth-child(2) {
          font-size: calc(30rem / 16);
          font-size: 500;
          margin-bottom: calc(2rem / 16);
        }

        >p:nth-child(3) {
          font-size: calc(30rem / 16);
          font-size: 500;
          margin-bottom: calc(2rem / 16);
          color: #ff0000;
        }

        >p:nth-child(4) {
          font-size: calc(30rem / 16);
          font-size: 500;
          margin-bottom: calc(2rem / 16);
        }

        >p:nth-child(5) {
          font-size: calc(30rem / 16);
          font-size: 500;
          margin-bottom: calc(2rem / 16);
          color: #ff0000;
        }

        >button {
          width: calc(100% - (60rem / 16));
          height: calc(60rem / 16);
          margin: calc(30rem / 16) auto 0;
          display: block;
          border-radius: 30px;
          border: 0;
          color: #fff;
          background: #48db8d;
          font-size: calc(22rem / 16);
          font-weight: 200;
        }
      }
    }
  }

  /deep/ .order_content {
    .backgroundColor(@white);
    .widthCalc(margin, 8rem);
    .widthCalc(padding, 8rem);
    .widthCalc(border-radius, 8rem)
  }

  .order_list {
    .list {
      .flex(normal, center);
      .widthCalc(margin-bottom, 4rem);

      &_label {
        .widthCalc(font-size, 16rem);
        // .widthCalc(margin-right, 8rem);
        .widthCalc(line-height, 20rem);
        .widthCalc(width, 88rem);
      }

      &_value {
        .flex(normal, center);
        .widthCalc(font-size, 16rem);
      }

      &_right {
        width: calc(100% - calc(88rem / 16));
        // .flex();
        display: flex;
        flex-wrap: wrap;

        &_img {
          .widthCalc(margin, 4rem);
          margin-bottom: 0;
          .flex(center, center);
        }
      }
    }

    .list:last-child {
      margin-bottom: 0;
    }
  }

  .popup {
    width: 80vw;
    padding: calc(15rem / 16) calc(20rem / 16);
    font-size: calc(15rem / 16);

    >p {
      margin-bottom: calc(8rem / 16);
    }
  }

  .colon {
    display: inline-block;
    margin: 0 4px;
    color: #b36900;
  }

  .block {
    display: inline-block;
    width: 22px;
    color: #fff;
    font-size: 12px;
    text-align: center;
    background-color: #b36900;
  }
}
</style>